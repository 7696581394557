import '../imageSlider/ImageSlider.css'
import security from '../../images/security.jpeg';
import labourer from '../../images/farm-worker.jpg';
import cleaner from '../../images/warehouse-cleaner.jpg';

function ImageSlider() {
    return (

        <div className="carousel slide" id='carouselDemo' data-bs-ride='carousel'>
            <div className='carousel-inner'>
                <div className='carousel-item active' data-bs-ride='5000'>
                    <img src={labourer} alt='nature' className='w-100'></img>
                    <div className='carousel-caption'>
                    <h5>General Labourers</h5>
                        <p>
                            Experienced labourers to work in many different sectors.</p>
                        
                    </div>
                </div>

                <div className="carousel-item "
                    data-bs-interval="5000">
                    <img src={security} className="w-100" alt='nature' />
                    <div className="carousel-caption">
                    <h5>Security Personel</h5>
                    <p>Static and event security</p>
                        </div>
                </div>
                <div className="carousel-item" data-bs-interval="5000">
                    <img src={cleaner} className="w-100" alt='nature' />
                    <div className="carousel-caption">
                        <h5>Cleaning services</h5>
                        <p>
                            Get experience cleaners for your business from us.
                        </p>
                    </div>
                </div>

            </div>

            <button className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselDemo"
                data-bs-slide="prev">
                <span className="carousel-control-prev-icon"></span>
            </button>

            <button className="carousel-control-next"
                type="button"
                data-bs-target="#carouselDemo"
                data-bs-slide="next">
                <span className="carousel-control-next-icon"></span>
            </button>

            <div className="carousel-indicators">
                <button type="button"
                    data-bs-target="#carouselDemo"
                    data-bs-slide-to="1">
                    <img src={labourer} alt='nature' />
                </button>

                <button type="button" className="active"
                    data-bs-target="#carouselDemo"
                    data-bs-slide-to="0">
                    <img src={security} alt='nature' />
                </button>

                <button type="button"
                    data-bs-target="#carouselDemo"
                    data-bs-slide-to="2">
                    <img src={cleaner} alt='nature' />
                </button>
            </div>
        </div>
    );
} export default ImageSlider;