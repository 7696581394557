import React from 'react';
import { PageBlock } from 'react-pageloom';
import './about.css';
import securityImg from '../../images/Security Guard.jpg';


/* const myTheme = {
	// Example theme object
	colors: {
		text: '#243c5a', // Deep Sea Blue
		background: '#71BCE1', // Sky blue
		primary: '#e84a5f', // Flamenco Red
		secondary: '#ff8474', // Salmon Pink
		navBackground: '#2a363b', // Swell Gray
		navText: '#fecea8', // Peach Orange
		activeLink: '#71BCE1', // Bittersweet
		hoverLink: '#e84a5f', // Flamenco Red
		mobileMenuBackground: '#2a363b', // Swell Gray
	display: 'flex', flexDirection: 'row'
    
	},
}; */

function About() {
	return (
		<PageBlock id='maindivContact'>
			<h1>About </h1>
			<div className='container'
			>
				<div className='div1'>
					<h5>Welcome to Bluenile Services
					</h5>

					<p>We provide the following services to our esteemed customers</p>
					<ul className="a">
						<li>Labourers</li>
						<li>Security services</li>
						<li>Cleaning services</li>
					</ul>
				</div>
				<div className='div2'>
					
					<p>We have general labourers working in various business settings including schools, hospitals, warehouses, retail and more.</p>
					<img width={'100%'} height={'400px'}alt='logo' 
              src={securityImg}></img></div>
			</div>

		</PageBlock>
	);
}
export default About;