import React from 'react';
import {PageBlock } from 'react-pageloom';
import Cleaner from '../../images/cleaner.jpg';


function Labourers () {
    return (
      <PageBlock id='maindivContact'>
			<h1>General Labourer </h1>
			<div className='container'
			>
				<div className='div1'>
					<h5>We provide general labourers to our clients accross all sectors.
					</h5>

					<p>The majority of general labourers work across fields such as construction, farming, warehouses and more.</p>
				
          <p>Our team of skilled cleaners work in hospitals, schools, restaurants and bars and retail providing professional services and leaving our clients and their customers certified.</p>
          </div>
				<div className='div2'>
					<p>Our general labourers are reasonably physically fit and are able to withstand the physical rigors of the jobs available in the UK</p>
				<img width={'100%'} height={'400px'} className='after-carousel-div' alt='logo' 
              src={Cleaner}></img></div>
			</div>

		</PageBlock>
    );
}
export default Labourers ;