import React from 'react';
import { PageWrapper, PageHeader, PageBlock, PageFooter, HeaderLogo } from 'react-pageloom';

import './App.css';
import Header from './components/header/Header';
import Labourers  from './components/labourer/Labourers';
import About from './components/about/About';
import Security from './components/security/Security';
import Footer from './components/footer/Footer';
import Logo from './images/blueNileLgo.jpeg'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle'

function App() {
  return (
    <PageWrapper fixedNav>
            <PageHeader id='headerPage' sx={{height: '500px'}}>
              <HeaderLogo id="section1"><img className='logo' alt='logo' 
              src={Logo}></img>
              </HeaderLogo>
                <Header />
            </PageHeader>

            <PageBlock id="section2" label="About" sx={{ height: '200px'}}>
              <About />
            </PageBlock>

            <PageBlock id="section3" label="Labourers">
              <Labourers  />
            </PageBlock>

            <PageBlock id="section4" label="Security Services">
              <Security />
            </PageBlock>

            {/* <PageBlock id="section5" label="Cleaning ">
              <Cleaning />
            </PageBlock> */}

            <PageFooter>
                <Footer />
            </PageFooter>
    </PageWrapper>
  );
}

export default App;
