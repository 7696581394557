import React from 'react';
import { PageBlock } from 'react-pageloom';
import securityImg from '../../images/Security Guard.jpg';
import './security.css'


function Security() {
    return (
      <PageBlock id='maindivContact'>
			<h1>Security Services </h1>
			<div className='container'
			>
				<div className='div1'>
					<h5>Welcome to Bluenile security services
					</h5>

					<p>We are currently offering a comprehensive package 
						which includes Static guarding, On call checks and 
						CCTV monitoring.</p>
						<p>We provide accredited personnel to maintain security and safety in different setors 
						such as residential complexes, commercial buildings, industrial facilities, warehouses, construction sites, retail stores, learning institutions and more.
						</p>
						<ul className="a">
						<li>Security Guarding</li>
						<li>Door Supervision</li>
						<li>Event Security</li>
						<li>Corporate Security</li>
						<li>Construction Site Security</li>
						<li>Key Holding</li>
					</ul>
				</div>
				<div className='div2'>
					<p>Benefit from the extensive capabilities of our Static security Guard personnel. Our gurards are well trained
						to handle crowds of people in gatherings like music concerts and more.
					</p>
				<img width={'100%'} height={'400px'} className='after-carousel-div' alt='logo' 
              src={securityImg}></img></div>
			</div>

		</PageBlock>
    );
}
export default Security;