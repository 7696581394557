import ImageSlider from '../imageSlider/ImageSlider';
import securityImg from '../../images/security.jpeg';
import './header.css';


function Header() {
    return (
      <section>
      <div style={{maxWidth: "1200px,", width: "100%",
        aspectRatio: "10/6",
        margin: "0 auto"
      }}><ImageSlider/>
      <div className='securityImg'>
      <img width={'100%'} height={'400px'} 
      className='after-carousel-div' 
      alt='logo' 
      src={securityImg}></img>
      </div>
      </div>
      </section>
    );
}
export default Header;