import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons';
function Footer() {
    return (
		<div className='footer'>
         <div className='contactIconsOnFooter'>
                <FontAwesomeIcon icon={faWhatsapp} className='whatsAppF'></FontAwesomeIcon>
                <span className="calendar-day">07579762585</span>
                <span className="calendar-day"></span>
                <FontAwesomeIcon icon={faEnvelope} className='envelopeF'></FontAwesomeIcon>
                <span className="calendar-day">info@bluenileservices.co.uk</span>
            </div>
      </div>
    );
}
export default Footer;